<template lang="">
  <BCard>
    <BRow>
      <BCol
        v-for="(icon, index) in iconsList"
        :key="index"
        cols="2"
        class="d-flex gap-1"
      >
        <IAmIcon
          :icon="icon"
          size="30"
          :color="getRandomColor()"
        />
        :
        <span class="font-medium-2">{{ icon }}</span>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

import icons from '@icons'

export default {
  components: {
    BCard, BRow, BCol,
  },
  setup() {
    function getRandomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }
    const iconsList = Object.keys(icons)
    return { iconsList, getRandomColor }
  },
}
</script>
<style lang="">

</style>
