var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', [_c('BRow', _vm._l(_vm.iconsList, function (icon, index) {
    return _c('BCol', {
      key: index,
      staticClass: "d-flex gap-1",
      attrs: {
        "cols": "2"
      }
    }, [_c('IAmIcon', {
      attrs: {
        "icon": icon,
        "size": "30",
        "color": _vm.getRandomColor()
      }
    }), _vm._v(" : "), _c('span', {
      staticClass: "font-medium-2"
    }, [_vm._v(_vm._s(icon))])], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }